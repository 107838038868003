<template>
  <div class="box">
    <div class="headline">岗位设置</div>
    <div class="content">
      <p>
        进入岗位设置界面，【系统设置】→【岗位设置】，如下图
        注意的是，如果在创建团队的时候选择了导入数据，可以直接看到导入岗位信息
      </p>
      <img src="../../assets/specificationImgs/img14.png" alt width="100%" />
      <p>选择“公司”，左侧树形图直观显示了公司的部门，单击《部门名称》，右下侧显示这个部门的岗位资料，选中其中一行《岗位》，列表以上显示岗位详细信息。</p>
      <p>
        1）新增：点击“新增”，选择《上级公司》与《上级部门》，按需填岗位信息，点击“确定”。<br />
        2）修改：从列表选中岗位，点击“修改”，按需修改岗位信息，点击“确定”。<br />
        1）删除：从列表选中岗位，点击“红色垃圾桶”删除。
      </p>
      <p>
        操作技巧： <br />
        1）“新增”或“修改”后，不需要保存，点击“取消”。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
